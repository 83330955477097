import React, { useEffect, useState } from 'react';
import { Client } from '../../schemas';
import { DigitalData } from '../../../lib';
import { FileManagementGrid } from '../../components/FileManagement/FileManagementGrid';
import { FileManagementPageHeader } from '../../components/FileManagement/Header';
import { FileUploadAlert } from '../../components/FileManagement/FileUploadAlert';
import { FinancialFile } from '../../schemas/fileManagement.schema';
import {
  Modal,
  PrimaryButton,
  SecondaryButton,
  TextField,
  Theme,
  TypeBase,
  TypeSubsectionHeader,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { TableLoadingSkeletonCell } from '../../components/Table/TableLoadingSkeletonCell';
import { TableLoadingSkeletonRow } from '../../components/Table/TableLoadingSkeletonRow';
import { useAsyncQuery } from '../../components/Async/useAsyncQuery';
import { useFileManagementFilters } from '../../components/FileManagement/FileManagementFilters';
import { useServices } from '../../services';

interface FileManagementPageProps {
  client: Client;
  isReadOnlyUser: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    '&.open': {
      top: '-1rem',
    },
    transition: '1s ease all',
    background: '#FFF',
    maxWidth: '95%',
    margin: '0 auto',
    padding: '1rem',
    borderRadius: '5px',
    zIndex: 1,
    top: '-10.8rem',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      maxWidth: '100%',
      top: 0,
      padding: '1rem 2rem',
      borderRadius: 0,
    },
    boxShadow: '0 8px 17px 2px rgba(0,0,0,0.14)',
  },
  modalTitle: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(5, 0),
    width: '100%',
  },
  buttonGroup: {
    '& > button': {
      margin: `auto ${theme.spacing(1)}px`,
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
  reviewText: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
  textFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
  },
  fileExtensionWrapper: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
}));

export const FileManagementPage: React.FC<FileManagementPageProps> = (props) => {
  const theme = useTheme();
  DigitalData.Hooks.usePageTitle('RF - File Management');
  DigitalData.Hooks.useSection('product', 'file management');

  const { fileManagementService } = useServices();
  let classes = useStyles();

  const { client, isReadOnlyUser } = props;
  const filters = useFileManagementFilters('rf-file-management-page');
  const filesUploaded = useAsyncQuery(
    () => fileManagementService.getUploadedFiles(client.uuid, filters.params),
    [client.uuid, filters.params],
  );
  const urgentlyRequiredDocuments = useAsyncQuery(
    () => fileManagementService.getUrgentlyRequiredDocuments(client.uuid),
    [client.uuid],
  );
  const actionMap = {
    DELETE: fileManagementService.deleteFile.bind(fileManagementService),
    DOWNLOAD: fileManagementService.downloadFile.bind(fileManagementService),
    RENAME: fileManagementService.renameFinancialFile.bind(fileManagementService),
  } as any;
  const [currentFile, setCurrentFile] = useState<FinancialFile | any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fileNameWithoutExt, setFileNameWithoutExt] = useState<string>('');
  const [fileExt, setFileExt] = useState<string>('');
  const [openNotesModal, setNotesModal] = useState<boolean>(false);
  const [reviewNotes, setReviewNotes] = useState<string>('');
  const fileActionHandler = async function (file: FinancialFile, action: string) {
    switch (action) {
      case 'RENAME':
        if (!openModal) {
          setCurrentFile(file);
          const fileNameArr = file.fileName.split('.');
          const temp = fileNameArr[fileNameArr.length - 1];
          setFileExt(temp);
          setFileNameWithoutExt(file.fileName.substring(0, file.fileName.length - temp.length - 1));
          setOpenModal(true);
          break;
        } else {
          const tempFile = JSON.parse(JSON.stringify(file));
          tempFile.fileName = fileNameWithoutExt;
          setCurrentFile(tempFile);
          setOpenModal(false);
          const response = await actionMap[action](client.uuid, tempFile);
          filesUploaded.refetch();
          break;
        }
      case 'DOWNLOAD': {
        const response = await actionMap[action](client.uuid, file.uuid);
        window.open(response.data.url);
        break;
      }
      case 'DELETE': {
        const response = await actionMap[action](client.uuid, file.uuid);
        filesUploaded.refetch();
        break;
      }
      case 'REVIEW_NOTES': {
        if (!openNotesModal) {
          setReviewNotes(file.reviewComments);
          setNotesModal(true);
        }
        break;
      }
    }
  };
  const handleRename = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileNameWithoutExt(e.target.value);
  };
  const exitDialog = () => {
    setOpenModal(false);
  };
  const exitNotesDialog = () => {
    setNotesModal(false);
  };

  return (
    <>
      {!urgentlyRequiredDocuments.isLoading && urgentlyRequiredDocuments.response?.data.length ? (
        <FileUploadAlert document={urgentlyRequiredDocuments} />
      ) : (
        <></>
      )}
      <FileManagementPageHeader
        isReadOnlyUser={isReadOnlyUser}
        client={client}
        onFileUploadComplete={filesUploaded.refetch}
      />
      <FileManagementGrid
        sorting={filters.sorting}
        pagination={filters.pagination}
        files={filesUploaded}
        tableSkeleton={
          <TableLoadingSkeletonRow rows={2}>
            <TableLoadingSkeletonCell width={4} transparent={true} />
            <TableLoadingSkeletonCell width={10} align={'left'} />
            <TableLoadingSkeletonCell width={10} align={'right'} />
            <TableLoadingSkeletonCell width={10} align={'center'} />
            <TableLoadingSkeletonCell width={6} align={'right'} transparent={true} />
          </TableLoadingSkeletonRow>
        }
        actionHandler={fileActionHandler}
        isReadOnlyUser={isReadOnlyUser}
      />
      <Modal
        open={openModal}
        variant="informational"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock={true}
        modalTitle={
          <div className={classes.title}>
            <TypeSubsectionHeader customColor={theme.palette.secondary.main} align={'center'}>
              Rename file : {currentFile?.fileName}
            </TypeSubsectionHeader>
          </div>
        }
        actionsContent={
          <div className={classes.buttonGroup}>
            <SecondaryButton onClick={exitDialog} style={{ fontSize: '1.2rem', fontWeight: 600 }}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                fileActionHandler(currentFile, 'RENAME');
              }}
              style={{ fontSize: '1.2rem', fontWeight: 600 }}
              data-cy={'confirm-rename'}
              data-testid={'confirm-rename'}
              disabled={!fileNameWithoutExt.trim()}
            >
              Confirm Rename
            </PrimaryButton>
          </div>
        }
      >
        <div className={classes.textFieldWrapper}>
          <TextField
            required
            id={'rename-label'}
            size="small"
            variant="outlined"
            label="Rename File"
            data-cy="renameFile"
            placeholder={'Enter File Name'}
            color={'primary'}
            value={fileNameWithoutExt}
            defaultValue={fileNameWithoutExt}
            onChange={handleRename}
          />
          <span className={classes.fileExtensionWrapper}>{'.' + fileExt}</span>
        </div>
      </Modal>
      <Modal
        open={openNotesModal}
        variant="informational"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        modalTitle={''}
        actionsContent={
          <div className={classes.buttonGroup}>
            <PrimaryButton
              onClick={() => {
                exitNotesDialog();
              }}
              style={{ fontSize: '1.2rem', fontWeight: 600 }}
              data-cy={'close-dialog'}
              data-testid={'close-notes-dialog'}
            >
              Close
            </PrimaryButton>
          </div>
        }
      >
        <div className={classes.reviewText}>
          <TypeBase>{reviewNotes}</TypeBase>
        </div>
      </Modal>
    </>
  );
};
